<div class="md:flex grid gap-4 grid-cols-1">
	<div class="md:w-1/3">
		<slot name="left"></slot>
	</div>
	<div class="md:w-1/3">
		<slot name="center"></slot>
	</div>
	<div class="md:w-1/3">
		<slot name="right"></slot>
	</div>
</div>
